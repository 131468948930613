



























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Loading: () => import('./Loading.vue'),
  },
})
export default class Deleted extends Vue {
  @Prop({ type: Boolean }) readonly canRestore?: boolean
  @Prop({ type: Boolean }) readonly isLoading?: boolean
}

import { render, staticRenderFns } from "./Deleted.vue?vue&type=template&id=73495722&scoped=true&"
import script from "./Deleted.vue?vue&type=script&lang=ts&"
export * from "./Deleted.vue?vue&type=script&lang=ts&"
import style0 from "./Deleted.vue?vue&type=style&index=0&id=73495722&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73495722",
  null
  
)

export default component.exports
import {QSpace} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSpace})
